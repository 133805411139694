import React from "react"

const Video = ({ src, className }) => {

  return (
    <video className={className} playsInline autoPlay muted loop>
      <source src={src ?? './videos/placeholder.mp4'} />
    </video>
  )
}

export default Video
