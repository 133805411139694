import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import Image from '~components/Image'
import resolveLink from '~utils/resolveLink'
import Video from '~components/Video'
import ClickVideo from '~components/blocks/ClickVideo'


const Slide = ({ slide, windowWidth, firstSlide }) => {

	let link 
	if(slide._type === 'homeSlide'){
		link = resolveLink(slide?.product?._type, slide?.product?.slug?.current)
	} else {
		if (slide?.link?.to){
			link = resolveLink(slide?.link?.to?._type, slide?.link?.to?.slug?.current, slide?.link?.to?.defaultParentCategory?.slug?.current) + (slide.queryString ? slide.queryString : '')
		} else if (slide?.link?.document && slide?.link?.linkType === 'internal'){
			link = resolveLink(slide?.link?.document?._type, slide?.link?.document?.slug?.current, slide?.link?.document?.defaultParentCategory?.slug?.current) + (slide.queryString ? slide.queryString : '')
		} else {
			link = slide?.link?.url
		}
	} 

	const ConditionalLink = ({ children }) => {
		if((!firstSlide && slide.mediaType === 'video') || !slide.link){
			return (
				<div>
					{children}
				</div>
			)
		} else {
			return (
				<Link to={link}>
					{children}
				</Link>
			)
		}
	}

	return (
		<div css={css`width: 100%;`}>
				{slide.mediaType === 'image' ?
				<ConditionalLink to={link}>
				<Image
					asset={ windowWidth <= 768 ? ( slide.mobileImage ?? slide.image ) : slide.image }
					aspectRatio={ windowWidth > 768 ? 1.6498 : 1 }
					css={css`
						display: block;
						height: auto;
						width: 100%;
						max-width: 100%;
						margin-right: 20px;
					`}
					/>
					</ConditionalLink>
					:
						<>
							{firstSlide ?
								<Video 
									src={windowWidth <= 768 ? slide.videoMobile?.asset?.url ?? slide.video?.asset?.url : slide.video?.asset?.url}
									css={css`
										display: block;
										height: auto;
										width: 100%;
										max-width: 100%;
										margin-right: 20px;
										aspect-ratio: 16 / 9;
									`}
								/>
								:
								<ClickVideo
									src={windowWidth <= 768 ? slide.videoMobile?.asset?.url ?? slide.video?.asset?.url : slide.video?.asset?.url}
									css={css`
										display: block;
										height: auto;
										width: 100%;
										max-width: 100%;
										margin-right: 20px;
										aspect-ratio: 16 / 9;
									`}
								/>
							}
						</>
				}
			
		</div>
	)
}

const Wrap = styled.div`
	
`

Slide.propTypes = {
	className: PropTypes.string
}

export default Slide